import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  checkboxClasses,
  Box,
  Tabs,
  Tab,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '../../../components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDebounce } from '../../../utils/useDebounce';
import {
  useFetchCompanies,
  useFetchCompanyByKvk,
} from '../../../data/companies';
import { useAdminUpdateUser } from '../../../data/admin';
import MiniteSnackbar from '../../../components/snackbar';
import { formatDate } from '../../../utils/dates';
import { Countries } from '../../../utils/consts';
import { getCountryCode, getCountryName } from '../../../utils/helpers';
import { postcodeValidator } from 'postcode-validator';

function a11yProps(index: boolean) {
  return {
    className: 'company-level-form-tabs',
    id: `company-level-tab-${+index}`,
    'aria-controls': `company-level-tabpanel-${+index}`,
  };
}

const CompanyInfo = ({ student }: { student: any }) => {
  const { mutateAsync: updateUserByAdmin } = useAdminUpdateUser();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const { t } = useTranslation('translation', {
    keyPrefix: 'settings_financial',
  });

  const countryOptions = Countries.map((country) => ({
    label: country.name,
    country: country.name,
    code: country.code,
  }));

  const schema = yup
    .object()
    .shape({
      company: yup.string().required(),
      kvk: yup
        .string()
        .matches(/\d{8}$|^$/)
        .optional()
        .nullable(),
      street: yup.string().required(),
      house: yup.string().required(),
      postal: yup
        .string()
        .test((value) => {
          if (!selectedCountryCode) return false;
          try {
            return postcodeValidator(value, selectedCountryCode);
          } catch (error) {
            return false;
          }
        })
        .required(),
      city: yup.string().required(),
      vat_number: yup
        .string()
        .matches(/[A-Za-z]{2}\d{9}B\d{2}$/)
        .nullable()
        .optional(),
      kor: yup.boolean().optional(),
      kor_approved_at: yup.date().when('kor', {
        is: true,
        then: yup
          .date()
          .required()
          .transform((value) => {
            if (isNaN(new Date(value).getTime())) {
              return null;
            }

            const now = new Date();
            const inputDate = new Date(value);

            if (now > inputDate) {
              return null;
            }

            return value;
          }),
        otherwise: yup
          .date()
          .nullable()
          .optional()
          .transform((value) => {
            if (isNaN(new Date(value).getTime())) {
              return null;
            }
            return value;
          }),
      }),
      country: yup.string().required(),
    })
    .required();

  const [search, setSearch] = useState('');
  const debouncedFilter = useDebounce(search, 500);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors, isValid, isDirty, isSubmitted },
    control,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      company: student?.company_name,
      kvk: student?.kvk_number,
      street: student?.street_name,
      house: student?.house_number,
      postal: student?.postal_code,
      city: student?.city,
      vat_number: student?.vat_number,
      showAllFields: true,
      manual: false,
      kor: !!student?.kor_approved_at,
      kor_approved_at: student?.kor_approved_at,
      country: getCountryName(student?.country) || 'Netherlands',
    },
  });

  /**
   * Used for postal code validation based on the country selected by the user
   */
  const selectedCountryName = watch('country');
  useEffect(() => {
    setSelectedCountryCode(getCountryCode(selectedCountryName));
  }, [selectedCountryName]);

  const selectedCompanyName = useWatch({ control, name: 'company' });
  const manualValue = useWatch({ control, name: 'manual' });
  const showAllValue = useWatch({ control, name: 'showAllFields' });
  const showStartDate = useWatch({ control, name: 'kor' });
  const [companyName, setCompanyName] = useState(null);

  const {
    data: companies = [],
    error,
    isLoading: companiesLoading,
  } = useFetchCompanies(debouncedFilter);

  const companyOptions = companies?.map((item) => {
    return {
      label: `${item.name} ${item.city || ''} ${item.kvkNumber}`,
      id: item.name,
      kvk: item.kvkNumber,
    };
  });

  const selectedCompanyKvk = companyOptions.find(
    (item) => item.id === selectedCompanyName,
  )?.kvk;

  const { data: companyData } = useFetchCompanyByKvk(
    selectedCompanyKvk,
    !manualValue,
  );

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);

      const { country: countryName } = data;
      const countryCode = getCountryCode(countryName);

      const payload: any = {
        user: {
          kvk_number: data?.kvk,
          street_name: data?.street,
          house_number: data?.house,
          postal_code: data?.postal,
          city: data?.city,
          vat_number: data?.vat_number,
          country: countryCode,
        },
        student: {
          company_name: data?.company,
          kor_approved_at: data?.kor
            ? formatDate(data?.kor_approved_at, 'YYYY-MM-DD') || null
            : null,
        },
      };

      await updateUserByAdmin({ userId: student.user_uid, dto: payload });

      reset(data);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error.message || error.message.toString(),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event: any) => {
    if (event && event.type === 'change') {
      setSearch(event.target.value);
    }
  };

  useEffect(() => {
    if (companyData) {
      setValue('company', companyData.company);
      setValue('kvk', companyData.kvk);
      setValue('street', companyData.street);
      setValue('house', companyData.house);
      setValue('city', companyData.city);
      setValue('postal', companyData.postal);
      trigger();
    }
  }, [companyData]);

  useEffect(() => {
    const { company_name } = student?.company || {};

    if (company_name) {
      setCompanyName(company_name);
    }
  }, [student]);

  useEffect(() => {
    if (companyName && !manualValue) {
      setValue('manual', true);
    }
  }, [error]);

  useEffect(() => {
    if (student?.company && student?.company?.company_name) {
      setSearch(student.company.company_name);
    }
  }, []);

  const handleFillManually = () => {
    setValue('company', null);
    setValue('kvk', null);
    setValue('street', null);
    setValue('house', null);
    setValue('city', null);
    setValue('postal', null);
    setValue('manual', true);
    setValue('showAllFields', true);
    setValue('country', null);
    trigger();
  };

  return (
    <section className="company-info-container">
      <Grid
        container
        direction="row"
        className="company-form-grid"
        alignItems="stretch"
        gap={2}
      >
        <Grid item xs={12} md={2}>
          <p className="company-forms-titles">Company info</p>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: '10px',
            }}
          >
            <Tabs
              value={Number(manualValue)}
              onChange={() => {
                setValue('manual', !manualValue);
                setCompanyName(null);
              }}
              centered
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#DEB900',
                },
              }}
            >
              <Tab label={t('autofill')} {...a11yProps(false)} />
              <Tab label={t('manual_input')} {...a11yProps(true)} />
            </Tabs>
          </Box>
          <form className="company-form">
            <>
              {!manualValue && (
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      loading={companiesLoading && !!debouncedFilter}
                      value={value}
                      loadingText={
                        <div style={{ display: 'flex' }}>
                          <CircularProgress size={14} sx={{ margin: 'auto' }} />
                        </div>
                      }
                      options={companyOptions}
                      onInputChange={handleSearch}
                      onChange={(e, data: { id: string } | null) => {
                        if (data) {
                          onChange(data.id);
                        } else {
                          onChange(null);
                        }
                      }}
                      isOptionEqualToValue={(option: { id: string }, value) => {
                        return option.id === value.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="form-input"
                          {...params}
                          variant="standard"
                          label={t('company_name')}
                        />
                      )}
                    />
                  )}
                  name="company"
                  control={control}
                />
              )}
              {manualValue && (
                <TextField
                  {...register('company')}
                  type="text"
                  className="form-input"
                  id="company-name-input"
                  label={t('company_name')}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              )}

              {error && !manualValue && !companyData && (
                <div className="fill-manually-hint-container">
                  <p className="no-results-text">
                    {t('no_result')} {search}
                  </p>
                  <p className="try-again-text">
                    {t('fill_in')}{' '}
                    <span
                      className="fill-manually-text"
                      onClick={handleFillManually}
                    >
                      {t('fill_manual')}
                    </span>
                  </p>
                </div>
              )}

              {(companyData || manualValue) && showAllValue && (
                <>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        value={value}
                        disabled={true}
                        data-testid="company-country"
                        id="company-country"
                        options={countryOptions}
                        onChange={(e, data: { country: string } | null) => {
                          if (data) {
                            onChange(data.country);
                          } else {
                            onChange(null);
                          }
                        }}
                        isOptionEqualToValue={(
                          option: { country: string },
                          value,
                        ) => {
                          return option.country === value.country;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            id="company-country-input"
                            label={t('company_country')}
                          />
                        )}
                      />
                    )}
                    name="country"
                    control={control}
                  />
                  <FormGroup className="form-group">
                    <TextField
                      {...register('street')}
                      className="form-group-input form-input"
                      name="street"
                      type="text"
                      id="street-input"
                      variant="standard"
                      label={t('street')}
                      error={!!errors.street}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                    <TextField
                      className="form-group-input form-input"
                      type="text"
                      id="house-input"
                      {...register('house')}
                      variant="standard"
                      label={t('house_number')}
                      name="house"
                      error={!!errors.house}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                  </FormGroup>

                  <FormGroup className="form-group">
                    <TextField
                      {...register('postal')}
                      className="form-group-input form-input"
                      type="text"
                      id="postal-input"
                      variant="standard"
                      label={t('postal')}
                      name="postal"
                      error={!!errors.postal}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />

                    <TextField
                      {...register('city')}
                      className="form-group-input form-input"
                      type="text"
                      id="city-input"
                      variant="standard"
                      label={t('city')}
                      name="city"
                      error={!!errors.city}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                  </FormGroup>

                  <FormGroup className="form-group">
                    <TextField
                      {...register('kvk')}
                      type="text"
                      id="kvk-input"
                      label={'Kvk Nr.'}
                      variant="standard"
                      className="form-input"
                      name="kvk"
                      disabled={!manualValue && !!selectedCompanyKvk}
                      error={!!errors.kvk}
                      inputProps={{
                        maxLength: 8,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      {...register('vat_number')}
                      type="text"
                      id="vat-input"
                      label={t('vat')}
                      className="form-input"
                      variant="standard"
                      error={!!errors.vat_number}
                      InputLabelProps={{ shrink: true }}
                      disabled={!manualValue}
                    />
                  </FormGroup>
                </>
              )}

              <FormGroup className="kor-container">
                <Controller
                  name="kor"
                  control={control}
                  render={({ field, field: { onChange } }) => (
                    <FormControlLabel
                      className="kor-container"
                      label={
                        <span>
                          {t('i_am')}{' '}
                          <a
                            className="business-link-label"
                            href="https://business.gov.nl/subsidy/small-businesses-scheme/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            &nbsp;
                            {t('kor_approve')}
                          </a>
                        </span>
                      }
                      {...field}
                      control={
                        <Checkbox
                          id="accept-checkbox"
                          onChange={onChange}
                          checked={showStartDate}
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#DEB900',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />
                <span className="kor-checkbox-helper-text">
                  {t('select_kor')}
                </span>
              </FormGroup>

              {showStartDate && (
                <FormGroup className="kor-approved-at">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <DesktopDatePicker
                          {...register('kor_approved_at', {
                            required: true,
                          })}
                          value={value}
                          minDate={new Date()}
                          onChange={(value) => onChange(value)}
                          disableMaskedInput={true}
                          label={t('start_date')}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              error={!!errors.kor_approved_at}
                            />
                          )}
                        />
                      )}
                      control={control}
                      name={`kor_approved_at`}
                    />
                  </LocalizationProvider>
                </FormGroup>
              )}
            </>
          </form>

          <div className="save-button-container">
            <Tooltip
              disableHoverListener={isValid}
              title="Please fill in all fields"
            >
              <span>
                <LoadingButton
                  isDisabled={!isValid || !isDirty}
                  className="save-button"
                  onClick={handleSubmit(onSubmit)}
                  buttonText={
                    !isDirty && isSubmitted
                      ? t('changes_saved')
                      : t('save_changes')
                  }
                  isLoading={isLoading}
                  confirmButtonTestId="save-company-info"
                />
              </span>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <MiniteSnackbar
        open={!!errorMessage}
        title={'Error'}
        message={errorMessage}
        autoHideDuration={4000}
        severity={'error'}
        onClose={() => setErrorMessage(null)}
      />
    </section>
  );
};

export default CompanyInfo;
